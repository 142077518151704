import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { QueryParamsService } from './query-params.service';

// Libraries
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  constructor(
    private http: HttpClient,
    private query: QueryParamsService
  ) {
  }
  public getPagesList(query: any): Observable<any> {
    const queryStr = this.query.getQueryString(query);

    return this.http.get(`/pages${queryStr}`);
  }

  public getPage(id: number): Observable<any> {
    return this.http.get(`/pages/${id}`);
  }

  public updatePage(id: number, data: any): Observable<any> {
    return this.http.put(`/pages/${id}`, data);
  }

  public createPage(data: any): Observable<any> {
    return this.http.post('/pages', data);
  }

  public deletePage(id: number): Observable<any> {
    return this.http.delete(`/pages/${id}`);
  }

}
