import { Component, OnInit, Output, EventEmitter , Input} from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input() searchLabel: string;

  @Output() onSearchChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }
}
