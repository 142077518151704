import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Libraries
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  constructor(
    private http: HttpClient
  ) { }

  public getTagsList(query: any): Observable<any> {
    return this.http.get(`/blog-tags?page=${query.page}&perPage=${query.perPage}`);
  }
}
