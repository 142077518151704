import { Component, OnInit, OnChanges, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import * as moment from 'moment';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit, OnChanges {
  @Input() label = '';
  @Input() disabled = false;

  @Output() onValueChange = new EventEmitter<any>();

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  todayDate = moment().format();

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled.currentValue !== undefined) {
      if (changes.disabled.currentValue) {
        this.range.controls.start.disable();
        this.range.controls.end.disable();
      } else {
        this.range.controls.start.enable();
        this.range.controls.end.enable();
      }
    }
  }

  onClose() {
    const startDate = this.range.value.start;
    const endDate = this.range.value.end;
    if (startDate && endDate) {
      const dateRange = {
        start: moment(this.range.value.start).format('YYYY-MM-DD'),
        end: moment(this.range.value.end).format('YYYY-MM-DD') + 'T23:59:59'
      };
      this.onValueChange.emit(dateRange);
    } else {
      this.clearData();
    }
  }

  clearData() {
    this.range.patchValue({start: null, end: null});
    this.onValueChange.emit({start: '', end: ''});
  }
}
