import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';

import {SelectDataInterface} from '@core/interfaces/select-data.interface';

@Component({
  selector: 'app-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss']
})
export class SelectFilterComponent implements OnInit {

  @Input() initialValue: string;
  @Input() valuesList: SelectDataInterface[];
  @Input() addAllOption = false;
  @Input() label = 'Select value';
  @Input() disabled = false;
  @Input() typeOfFilter;

  @Output() onValueSelected = new EventEmitter<string>();

  public model: string;

  constructor() {
  }

  ngOnInit(): void {
    if (this.addAllOption) {
      this.valuesList.unshift({
        value: '',
        viewValue: 'All'
      });
    }
    this.model = this.initialValue || this.valuesList[0].value;
  }

  public onValueChange(value: string): void {
    const data = {data: value, type: this.typeOfFilter};
    this.onValueSelected.emit(JSON.stringify(data));
  }
}
