import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QueryParamsService } from '@core/services/query-params.service';

// Libraries
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlogCategoriesService {

  constructor(
    private http: HttpClient,
    private query: QueryParamsService
  ) { }

  public getCategoriesList(query: any): Observable<any> {
    const queryStr = this.query.getQueryString(query);

    return this.http.get(`/blog-categories${queryStr}`);
  }

  public getCategoryData(id: number): Observable<any> {
    return this.http.get(`/blog-categories/${id}?groups[]=translations`);
  }

  public createCategory(categoryData: any): Observable<any> {
    return this.http.post('/blog-categories', categoryData);
  }

  public editCategory(id: number, categoryData: any): Observable<any> {
    return this.http.put(`/blog-categories/${id}`, categoryData);
  }

  public deleteCategory(id: number): Observable<any> {
    return this.http.delete(`/blog-categories/${id}`);
  }
}
