import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { STORAGE_KEYS } from '@core/constants/storage-keys.constant';

// Libraries
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  public setCurrentUser(currentUser: string): void {
    const user = JSON.stringify(currentUser);
    localStorage.setItem(STORAGE_KEYS.CURRENT_USER, user);
  }

  public getCurrentUser(): any {
    const userInfo = localStorage.getItem(STORAGE_KEYS.CURRENT_USER);
    return userInfo ? JSON.parse(userInfo) : null;
  }

  public removeCurrentUser(): void {
    localStorage.removeItem(STORAGE_KEYS.CURRENT_USER);
  }

  public setAuthToken(token: string): void {
    localStorage.setItem(STORAGE_KEYS.AUTH_TOKEN, token);
  }

  public getAuthToken(): string {
    return localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN);
  }

  public removeAuthToken(): void {
    localStorage.removeItem(STORAGE_KEYS.AUTH_TOKEN);
  }

  public setRefreshToken(token: string): void {
    localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, token);
  }

  public getRefreshToken(): string {
    return localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
  }

  public removeRefreshToken(): void {
    localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
  }

  public login(data): Observable<any> {
    return this.http.post('token/create', data);
  }

  refreshTokenRequest(data): Observable<any> {
    return this.http.post('token/refresh', data).pipe(
      tap((resp) => {
        this.setAuthToken(resp.token);
        this.setRefreshToken(resp.refreshToken);
      })
    );
  }

  public logOut(): void {
    this.removeCurrentUser();
    this.removeAuthToken();
    this.removeRefreshToken();
    this.router.navigate(['login']);
  }

  public getUsersInfoFromServer(): Observable<any> {
    return this.http.post('users/info', {});
  }
}
