import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Libraries
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  constructor(
    private http: HttpClient
  ) { }

  public getTemplatesList(query: any): Observable<any> {
    return this.http.get(`/notification-templates?page=${query.page}&perPage=${query.perPage}`);
  }

  public getTemplateById(id: number): Observable<any> {
    return this.http.get(`/notification-templates/${id}?groups[]=translations`);
  }

  public updateTemplate(id: number, data: any): Observable<any> {
    return this.http.put(`/notification-templates/${id}`, data);
  }

  public getKeywords(data: any): Observable<any> {
    return this.http.post(`/notification-templates/keywords`, data);
  }
}
