import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

// Environment
import { environment } from '@env/environment';

// Services
import { AuthService } from '../services/auth.service';
import { HttpRequestsCountService } from '@core/services/http-requests-count.service';

// Libraries
import {Observable, throwError} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  private apiUrl = environment.apiUrl;

  constructor(
    private authService: AuthService,
    private requestCounter: HttpRequestsCountService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.requestCounter.increaseRequestByOne();
    const authToken = this.authService.getAuthToken();

    if (authToken) {
      request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${authToken}`) });
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    if (!request.headers.has('Accept')) {
      request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    }
    // Prepare Url
    const data = {
      url: this.prepareUrl(request.url)
    };
    request = request.clone(data);

    return next.handle(request).pipe(
      tap((event) => {
          if (event instanceof HttpResponse) {
            this.requestCounter.decreaseRequestByOne();
          }
      }),
      catchError(err => {
        this.requestCounter.decreaseRequestByOne();

        if ((!this.isRefreshRequest(request.url)) && (err.status === 401 || err.status === 403)) {
          // Try to refresh token if 401 or 403 response returned from api
          return this.refreshToken()
            .pipe(
              switchMap(() => {
                const newAuthToken = this.authService.getAuthToken();

                if (authToken) {
                  request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${newAuthToken}`) });
                }
                return next.handle(request);
              }),
              catchError((e) => {
                this.authService.logOut();
                return throwError(e);
              })
            );
        }

        return throwError(err);
      })
    );
  }

  private refreshToken() {
    const refreshToken = this.authService.getRefreshToken();
    if (refreshToken) {
      return this.authService.refreshTokenRequest({refreshToken}).pipe(
        tap((resp) => {
          console.log('Token refreshed: ', resp);
        })
      );
    } else {
      return throwError(null);
    }
  }

  private isRefreshRequest(requestUrl: string): boolean {
    if (!requestUrl) {
      return false;
    }
    const REFRESH_URL_KEY = 'token/refresh';
    return requestUrl.includes(REFRESH_URL_KEY);
  }

  private isAbsoluteUrl(url: string): boolean {
    const absolutePattern = /^https?:\/\//i;
    return absolutePattern.test(url);
  }

  private prepareUrl(url: string): string {
    url = this.isAbsoluteUrl(url) ? url : `${this.apiUrl}/${url}`;
    return url.replace(/([^:]\/)\/+/g, '$1');
  }
}
