<div class="picker-container">
  <mat-form-field>
    <mat-label>{{label}}</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="todayDate">
      <input matStartDate formControlName="start" placeholder="Start date">
      <input matEndDate formControlName="end" placeholder="End date">
    </mat-date-range-input>
    <button
      mat-button
      *ngIf="range.value.start && range.value.end"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      matTooltip="Clear Data">

      <mat-icon class="icon-default" (click)="clearData()">close</mat-icon>
    </button>
    <mat-datepicker-toggle matSuffix [for]="picker" matTooltip="Select Range"></mat-datepicker-toggle>
    <mat-date-range-picker #picker (closed)="onClose()"></mat-date-range-picker>
  </mat-form-field>
</div>
