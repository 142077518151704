<form [formGroup]="reactiveForm">

  <mat-form-field class="search">
    <mat-label>{{searchLabel}}</mat-label>
    <input matInput formControlName="search" name="search" type="text">
    <button mat-button matSuffix mat-icon-button aria-label="Search" >
      <mat-icon class="icon-default">search</mat-icon>
    </button>
  </mat-form-field>
</form>
