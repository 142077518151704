import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestsCountService {
  public activeRequestsCount = new BehaviorSubject(0);

  constructor() { }

  increaseRequestByOne(): void {
    const currentRequests = this.activeRequestsCount.getValue();
    const increased = currentRequests + 1;
    this.activeRequestsCount.next(increased);
  }

  decreaseRequestByOne(): void {
    const currentRequests = this.activeRequestsCount.getValue();
    const increased = currentRequests - 1;
    this.activeRequestsCount.next(increased);
  }
}
