import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { QueryParamsService } from './query-params.service';

// Libraries
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private http: HttpClient,
    private query: QueryParamsService
  ) { }

  public getUsersList(query: any): Observable<any> {
    const queryStr = this.query.getQueryString(query);

    return this.http.get(`/users${queryStr}`);
  }

  public createUser(usersData: any): Observable<any> {
    return this.http.post('/users', usersData);
  }

  public editUser(id: number, usersData: any): Observable<any> {
    return this.http.put(`/users/${id}`, usersData);
  }

  public deleteUser(id: number): Observable<any> {
    return this.http.delete(`/users/${id}`);
  }
}
