import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { MaterialModule } from './modules/material.module';

// Components
import { DynamicSearchComponent } from './components/dynamic-search/dynamic-search.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SelectFilterComponent } from './components/select-filter/select-filter.component';
import { TagsChipsComponent } from './components/tags-chips/tags-chips.component';
import { DateRangePickerComponent } from '@shared/components/date-range-picker/date-range-picker.component';


const MODULES = [
  MaterialModule
];

const COMPONENTS = [
  DynamicSearchComponent,
  LoaderComponent,
  SelectFilterComponent,
  TagsChipsComponent,
  DateRangePickerComponent
];
//
// const directives = [];
//
// const pipes = [];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    ...MODULES
  ],
  providers: [
  ],
  exports: [
    ...MODULES,
    ...COMPONENTS
  ]
})
export class SharedModule { }
