import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';

// Services
import { AuthService } from '../services/auth.service';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  public currentUser: any;
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean {
    return this.canGetTokens();
  }

  canActivateChild(): boolean {
    return this.canGetTokens();
  }

  canGetTokens(): boolean {
    const canGetTokens = !!this.authService.getAuthToken() && !!this.authService.getRefreshToken();
    if (!canGetTokens) {
      this.router.navigate(['login']);
    }
    return canGetTokens;
  }
}
