import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QueryParamsService {

  constructor() {
  }

  public getQueryString(queries): string {
    const queryArr = [];
    let queryStr = '';

    if (queries.page) {
      queryArr.push(`page=${queries.page}`);
    }
    if (queries.perPage) {
      queryArr.push(`perPage=${queries.perPage}`);
    }
    if (queries.sort) {
      queryArr.push(`order[sort]=asc`);
    }
    if (queries.module) {
      queryArr.push(`module=${queries.module}`);
    }

    if (queries.search) {
      queryArr.push(`search=${queries.search}`);
    }

    if (queries.isActive) {
      queryArr.push(`isActive=${queries.isActive}`);
    }

    if (queries.role) {
      queryArr.push(`searchJson=${queries.role}`);
    }

    if (queries.startDate) {
      queryArr.push(`createdAt[after]=${queries.startDate}`);
    }

    if (queries.endDate) {
      queryArr.push(`createdAt[before]=${queries.endDate}`);
    }

    if (queryArr.length) {
      queryStr = '?' + queryArr.join('&');
    }

    return queryStr;
  }

}
