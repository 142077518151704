import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QueryParamsService } from '@core/services/query-params.service';

// Libraries
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserGroupsService {

  constructor(
    private http: HttpClient,
    private query: QueryParamsService
  ) { }

  public getGroupsList(query: any): Observable<any> {
    const queryStr = this.query.getQueryString(query);

    return this.http.get(`/user-groups${queryStr}`);
  }

  public createGroup(usersData: any): Observable<any> {
    return this.http.post('/user-groups', usersData);
  }

  public editGroup(id: number, usersData: any): Observable<any> {
    return this.http.put(`/user-groups/${id}`, usersData);
  }

  public deleteGroup(id: number): Observable<any> {
    return this.http.delete(`/user-groups/${id}`);
  }
}
