import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Interceptors
import { HttpConfigInterceptor } from './interceptors/http-config.interceptor';

// Modules
import { SharedModule } from '@shared/shared.module';

// Services
import { AuthService } from './services/auth.service';
import { UsersService } from './services/users.service';
import { PagesService } from '@core/services/pages.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { BlogCategoriesService } from '@core/services/blog-categories.service';
import { TagsService } from '@core/services/tags.service';
import { UserGroupsService } from '@core/services/user-groups.service';
import { TemplatesService } from '@core/services/templates.service';
import { QueryParamsService } from '@core/services/query-params.service';
import { HttpRequestsCountService } from '@core/services/http-requests-count.service';

// Guards
import { AuthGuard } from './guards/auth.guard';
import { UnAuthGuard } from './guards/un-auth.guard';

const modules = [
  HttpClientModule,
  SharedModule
];

const services = [
  AuthService,
  PagesService,
  UsersService,
  SnackBarService,
  BlogCategoriesService,
  TagsService,
  UserGroupsService,
  TemplatesService,
  QueryParamsService,
  HttpRequestsCountService
];

const guards = [
  AuthGuard,
  UnAuthGuard
];

@NgModule({
  imports: [
    CommonModule,
    ...modules
  ],
  exports: [
    ...modules
  ],
  providers: [
    ...services,
    ...guards,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`CoreModule has already been loaded. Import Core modules in the AppModule only.`);
    }
  }
}
