import { Component, OnInit, Output, EventEmitter , Input} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-dynamic-search',
  templateUrl: './dynamic-search.component.html',
  styleUrls: ['./dynamic-search.component.scss']
})
export class DynamicSearchComponent implements OnInit {
  public reactiveForm: FormGroup;
  public currentValue: string;

  @Input() searchLabel: string;

  @Output() onSearchChange = new EventEmitter<string>();

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.reactiveForm = this.formBuilder.group({
      search: ['']
    });

    this.reactiveForm.valueChanges.subscribe(resp => {
      this.emitSearchEvent(resp.search);
    });
  }

  emitSearchEvent(search) {
    this.currentValue = search;
    const searchDelay = 2000;
    const timeout = setTimeout(() => {
      if (this.currentValue === search) {
        this.onSearchChange.emit(search);
      } else {
        clearTimeout(timeout);
      }
    }, searchDelay);
  }
}
