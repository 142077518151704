import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(
    private snackBar: MatSnackBar
  ) { }

  showSimple(msg: string, duration: number) {
    this.snackBar.open(msg, null, {
      duration: duration * 1000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom'
    });
  }
}
